<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <div class="wrap">
                   <div v-for="(item,index) in list" :key="index" class="item" @click="go(item.url)">
                       <div class="icon"><img :src="item.src" alt=""></div>
                       <div class="title">{{item.name}}</div>
                       <div class="btn" @click="go(item.url)">进入</div>
                   </div>
                </div>
            </el-main>
        </el-container>

    </div>
</template>

<script>
    export default {
        name: "jhtb",
        data(){
            return{
                list:[
                    {url:'/plainFilling/heighRoad',name:'高速和普通国省道',src:require('../../assets/images/jhgl/fl001.png')},
                    {url:'/plainFilling/waterRoad',name:'水运',src:require('../../assets/images/jhgl/fl002.png')},
                    // {url:'/plainFilling/zcxt',name:'支持系统',src:require('../../assets/images/jhgl/fl003.png')},
                    // {url:'/plainFilling/sggl',name:'综合交通（疏港公路）',src:require('../../assets/images/jhgl/fl004.png')},
                    // {url:'/plainFilling/sgtl',name:'综合交通（疏港铁路）',src:require('../../assets/images/jhgl/fl005.png')},
                    {url:'/plainFilling/sncz',name:'枢纽场站',src:require('../../assets/images/jhgl/fl006.png')},
                    {url:'/plainFilling/bridge',name:'危桥计划填报',src:require('../../assets/images/jhgl/fl012.png')},
                    // {url:'/plainFilling/hysn',name:'综合交通（货运枢纽-物流园区）',src:require('../../assets/images/jhgl/fl007.png')},
                    // {url:'/plainFilling/kyz',name:'市县客运站',src:require('../../assets/images/jhgl/fl008.png')},
                    // {url:'/plainFilling/hyxxh',name:'行业信息化',src:require('../../assets/images/jhgl/fl009.png')},
                    // {url:'/plainFilling/yjdb',name:'以奖代补',src:require('../../assets/images/jhgl/fl010.png')},
                    {url:'/plainFilling/confirmationLetter',name:'计划申请及确认文件',src:require('../../assets/images/jhgl/fl011.png')},
                    // {url:'/plainFilling/confirmationLetter',name:'灾毁恢复重建项目计划申请文件',src:require('../../assets/images/jhgl/fl011.png')},
                    {url:'/plainFilling/bridgeConfirmationLetter',name:'危桥计划申请及确认文件',src:require('../../assets/images/jhgl/fl012.png')},
                    {url:'/plainFilling/disaster',name:'国债项目情况填报',src:require('../../assets/images/jhgl/fl013.png')},
                    ]
            }
        },
        methods:{
            go(url){
                console.log(url)
                this.$router.push(url)
            }
        }
    }
</script>

<style scoped lang="less">
    .roadFront{
        padding: 40px;
        .wrap{
            margin: 0 auto;
            display: flex;
            align-items: center;
            /*justify-content: space-around;*/
            flex-wrap: wrap;
            .item{
                flex-shrink: 1;
                overflow: visible;
                margin: 60px 24px;
                width: 300px;
                height: 145px;
                background: rgba(255,255,255,0.39);
                box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
                opacity: 1;
                border-radius: 10px;
                .icon{
                    margin: -44px auto;
                    width: 88px;
                    height: 88px;
                    background: rgba(255,255,255,1);
                    border: 4px solid #F4F6F7;
                    border-radius: 50%;
                    opacity: 1;
                    img{
                        width: 88px;
                        height: 88px;
                    }
                }
                .title{
                    margin-top: 51px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #030303;
                    text-align: center;
                }
                .btn{
                    margin: 20px auto 0;
                    text-align: center;
                    width: 66px;
                    height: 28px;
                    background: rgba(90,159,250);
                    border-radius: 4px;
                    color: #fff;
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
        .wrap:after{
            content: "";
            width: 300px;
        }
    }

</style>
